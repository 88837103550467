@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

.title-color {
  color: #fca311;
}

.styleContent1 {
  font-family: "Satisfy", cursive;
  font-size: 1.5rem;
}

.styleContent2 {
  font-family: "Satisfy", cursive;
  font-size: 1.5rem;
}

.myName {
  font-size: 3rem;
  color: #5367cb;
}
